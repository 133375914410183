import React, { Component } from 'react';
import SEO from '../components/SEO';
import HeadingSection from '../components/HeadingSection';
import SuccessModalBlock from '../components/SuccessModalBlock';
import imageIllustrationContactUs from '../assets/images/illustrations/Contact_us.png';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

const GetInTouch = () => (
  <>
    <SEO title="Get in touch" description="" />

    <div className="common-page get-in-touch">
      <HeadingSection
        h1="We'd love to hear
          from you"
        image={imageIllustrationContactUs}
      />
      <ContactForm />
    </div>
  </>
);

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: (typeof location !== 'undefined' && location?.search?.includes('close_account')) ? 'Close account' : ''
    };
    this.childSuccessModalBlockRef = React.createRef();
  }

  handleChange = (event) => {
    const { value, name } = event.target;

    this.setState({ [name]: value });
  };

  handleSubmitForm = (event) => {
    event.preventDefault();

    fetch('/', {
      method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      body: encode({
        'form-name': 'sprive-form',
        ...this.state,
      }),
    })
    .then((response) => {
      if (response.ok) {
        this.childSuccessModalBlockRef.current.openModal();
        this.setState({name: '', email: '', message: ''});
      }
    })
    .catch((error) => console.log(error));

  };

  render() {
    const { name, email, message, category } = this.state;

    return (
      <>
        <section className="first-section">
          <div className="container">
            <div className="first-section_content">
              <h2>Email us</h2>
              <form
                className="wrapper"
                method="POST"
                action="/"
                name="sprive-form"
                data-netlify="true"
                netlify-honeypot="bot-field"
                onSubmit={this.handleSubmitForm}
              >
                <input type="hidden" name="form-name" value="sprive-form" />
                <div hidden>
                  <label>
                    Don’t fill this out if you're human: <input name="bot-field" />
                  </label>
                </div>

                <input
                  value={name}
                  onChange={this.handleChange}
                  name="name"
                  className="name"
                  type="text"
                  placeholder="Your name"
                  required
                />
                <input
                  value={email}
                  onChange={this.handleChange}
                  name="email"
                  className="email"
                  type="email"
                  placeholder="Your email address"
                  required
                />
                <select
                  name="category"
                  className="email category"
                  onChange={this.handleChange}
                  required
                >
                  <option value="" disabled selected>Issue Category</option>
                  <option value="General feedback">General feedback</option>
                  <option value="Feature enhancement">Feature enhancement</option>
                  <option value="Report an issue">Report an issue</option>
                  <option value="Question">Question</option>
                  {/*If we find the string 'close_account' in the query string of the page url, we pre-select this option:*/}
                  <option value="Close account" selected={category === 'Close account'}>Close account</option>
                </select>
                <textarea
                  value={message}
                  onChange={this.handleChange}
                  name="message"
                  className="comment"
                  type="text"
                  placeholder="Your message"
                  required
                />
                <button name="send-button" type="submit">
                  Send message
                </button>
              </form>
            </div>
          </div>
        </section>
        <SuccessModalBlock ref={this.childSuccessModalBlockRef} />
      </>
    );
  }
}

export default GetInTouch;
